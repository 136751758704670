<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col.px-0
        v-textarea(
          filled
          @change="$store.commit('setComment', $event)"
          rows='4'
          label="If you have any additional items, questions or comments please enter them here."
          :value="$store.state.comment"
        )
</template>

<script>
export default {
  name: 'AdditionalComments'
}
</script>
